import { useEffect } from 'react'

import { useAlert } from 'hooks'

// This hook looks for messages regarding auth_providers and renders them through as alerts.
const AuthFeedback = () => {
  const params = new URLSearchParams(window.location.search)
  const provider = params.get('auth_provider')
  const success = params.get('auth_success') === 'true'
  const status = params.get('auth_status')

  const setAlert = useAlert()

  useEffect(() => {
    const statusProps = {
      account_created: {
        message: `Welcome to your new account! If you meant to sign in to an existing account instead, please sign out, and sign in with your existing account.`,
        cta: {
          text: 'Sign Out',
          link: '/signout?redirect=signin'
        }
      },
      email_exists: {
        message:
          'The email address provided is registered to another account. Please sign in to Alo Moves using your email, then connect your account to Apple in your Account Settings.',
        cta: {
          text: 'Sign In',
          link: '/signin'
        }
      },
      google_exists: {
        message:
          'The email address provided is registered to another account. Please sign in to Alo Moves using your email, then connect your account to Google in your Account Settings.',
        cta: {
          text: 'Sign In',
          link: '/signin'
        }
      },
      account_connected: {
        message: `Your ${provider} account is now linked to your Alo Moves account. You can now log in using ${provider}.`
      },
      merge_failed: {
        message: `We were unable to connect your ${provider} account. Please contact support@alomoves.com and we will be more than happy to help you.`
      },
      provider_error: {
        message: `The ${provider} service is currently unavailable. Please try again later.`
      }
    }

    if (status) {
      const alert = statusProps[status]
      if (alert) {
        setAlert({
          ...alert,
          error: !success
        })
      }
    }
  }, [provider, success, status])
}

export default AuthFeedback
