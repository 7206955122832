import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import uuidv4 from 'uuid/v4'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'
import { css } from '@styled-system/css'

import { axios } from 'api'

import { Button, SVGImage, P } from 'ui/bend'
import { callAll } from 'utils'
import { sign_up_initiated_event } from 'gtm/pushEvents'

const StyledError = styled(P)(
  css({
    color: 'error',
    mt: '5px',
    mb: '0px',
    textAlign: 'center',
    lineHeight: 'initial'
  })
)

const AuthProvider = ({
  name,
  SVG,
  authID,
  ctaPrefix,
  successUrl,
  failureUrl,
  label,
  ...rest
}) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const connected = !!authID
  const [, setCookie] = useCookies(['oauth_anticipate_id'])

  const handleClick = (e) => {
    e.preventDefault()
    connected ? disconnect() : connect()
  }

  const disconnect = () => {
    window.location.href = `/auth/remove/${authID}`
  }

  const connect = async () => {
    setProcessing(true)

    // Give this OAuth request a UUID (set via a cookie) so that our server knows
    // where to redirect the client after the provider redirects back to our server
    const uuid = uuidv4()
    setCookie('oauth_anticipate_id', uuid, { path: '/' })

    try {
      // Tell our server that we are about to kick off an OAuth request
      // and provide success/fail redirect URLs
      const response = await axios.post('/oauth/anticipate', {
        success: successUrl,
        failure: failureUrl
      })

      if (response.status === 200) {
        // Kick off the OAuth request
        window.location.href = `/auth/${name}`
      }
    } catch (error) {
      setError(
        'An unexpected error occurred. Please contact support@alomoves.com for assistance.'
      )
      setProcessing(false)
    }
  }

  return (
    <Fragment>
      <Button
        id={`${name}_oauth`}
        onClick={callAll(() => sign_up_initiated_event(name), handleClick)}
        processing={processing}
        variant='dim'
        width='100%'
        {...rest}
        style={{
          display: !processing ? 'flex': '',
          justifyContent: !processing ? 'flex-start' : '',
          padding: '0 10px',
          minHeight: '40px'
        }}
      >
        {SVG && <SVGImage SVG={SVG} width='22px' height='22px' mr='16px' />}
        {connected ? 'Disconnect from' : ctaPrefix} {label || name}
      </Button>

      {error && <StyledError>{error}</StyledError>}
    </Fragment>
  )
}

AuthProvider.propTypes = {
  name: PropTypes.string.isRequired,
  SVG: PropTypes.any,
  authID: PropTypes.number,
  ctaPrefix: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
  failureUrl: PropTypes.string.isRequired
}

export default AuthProvider
