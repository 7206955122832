import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { AppleLogo, GoogleLogo, FacebookLogoNew } from 'images'

import AuthProvider from './AuthProvider'
import { useResetPage } from 'hooks'

const AuthProviders = ({ context, authorizations, signinRedirect }) => {
  const providers = [
    {
      name: 'apple',
      SVG: AppleLogo,
      authID: authorizations.apple
    },
    {
      name: 'facebook',
      SVG: FacebookLogoNew,
      authID: authorizations.facebook
    },
    {
      disabled: false,
      name: 'google_oauth2',
      SVG: GoogleLogo,
      authID: authorizations.google,
      label: 'google'
    }
  ]

  const contextProps = {
    signin: {
      ctaPrefix: 'Sign in with',
      successUrl: signinRedirect || '/subscribe/membership', // If user already has membership, server will redirect to home
      failureUrl: '/signin'
    },
    signup: {
      ctaPrefix: 'Continue with',
      successUrl: '/subscribe/membership',
      failureUrl: '/subscribe/signup'
    },
    connect: {
      ctaPrefix: 'Connect with',
      successUrl: '/settings/account',
      failureUrl: '/settings/account'
    }
  }

  useResetPage()

  return (
    <Fragment>
      {providers
        .filter((i) => !i.disabled)
        .map((provider, index) => (
          <AuthProvider
            key={provider.name}
            {...provider}
            {...contextProps[context]}
            mt={index !== 0 && ['8px', '16px']}
          />
        ))}
    </Fragment>
  )
}

AuthProviders.defaultProps = {
  authorizations: {}
}

// context: Which page are we on: /signin or /settings/account (connect)
// authorizations: Object of authorization IDs, keyed by provider (if connected)
AuthProviders.propTypes = {
  context: PropTypes.string.isRequired, // signin, signup, connect
  authorizations: PropTypes.shape({
    apple: PropTypes.number,
    facebook: PropTypes.number
  }),
  signinRedirect: PropTypes.string
}

export default AuthProviders
