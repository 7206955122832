import { useCallback, useEffect, useState } from 'react'
import { useStripeCheckout, useUserContext } from '../../context'
import { useAlert } from '../../hooks'
import { axios } from '../../api'
import { toast } from 'react-toastify'

const noop = () => {}

const toastId = 'pending-payment'
export const PendingPayment = ({
  refresh = false,
  onError = noop,
  onSuccess = noop,
  onComplete = noop
}) => {
  const getStripe = useStripeCheckout()
  const { user } = useUserContext()
  const setAlert = useAlert()
  const [pendingPayment, setPendingPayment] = useState(user?.pendingPayment)

  const confirmPayment = useCallback(async () => {
    if (!getStripe) return
    const stripe = await getStripe()
    // get the checkout sessionID for the pending payment
    toast.dismiss(toastId)
    setAlert(
      {
        message: 'Please wait till we confirm your payment',
        cta: {},
        alert: false,
        error: true
      },
      {
        toastId: toastId,
        draggable: false,
        closeButton: false,
        closeOnClick: false
      }
    )
    try {
      let res = await axios.get('/payments/pending_intent')
      if (res?.data?.clientSecret) {
        let { origin, href } = window.location
        const { error } = await stripe.confirmPayment({
          clientSecret: res.data.clientSecret,
          confirmParams: {
            return_url: `${origin}/payment/confirm/${btoa(href)}`
          }
        })
        if (error) {
          onError(error)
          return
        } else {
          toast.dismiss(toastId)
          onSuccess()
        }
      } else {
        // the session not returning usually means that the request is incorrect
        toast.dismiss(toastId)
        // we don't need to call onSuccess here
      }
      setAlert({
        message: 'We have updated your membership.'
      })
    } catch (err) {
      console.log(err)
      // TODO handle 410 response
      onError(err)
    } finally {
      toast.dismiss(toastId)
      onComplete()
    }
  }, [getStripe, onComplete, onError, onSuccess, setAlert])

  useEffect(() => {
    if (pendingPayment) {
      setAlert(
        {
          message: `There is a pending payment of ${pendingPayment.amount}. Please confirm the payment.`,
          cta: {
            text: `Pay ${pendingPayment.amount}`,
            link: '#',
            onClick: async (e) => {
              e.preventDefault()
              await confirmPayment()
            }
          },
          alert: false,
          error: true,
          width: ['100%', '50em']
        },
        {
          toastId: toastId,
          draggable: false,
          closeButton: false,
          closeOnClick: false
        }
      )
    } else {
      toast.dismiss(toastId)
    }
  }, [confirmPayment, pendingPayment, setAlert])

  useEffect(() => {
    if (refresh) {
      axios
        .get('/payments/pending_intent')
        .then((res) => {
          setPendingPayment(res?.data)
        })
        .catch((err) => console.log(err))
    }
  }, [refresh])

  return null
}
