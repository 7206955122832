import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { CookiesProvider } from 'react-cookie'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { UserProvider, ModalProvider } from 'context'
import { LightTheme } from 'ui/bend'

const queryClientInstance = new QueryClient()

const EntryProviders = ({
  loadUser,
  user,
  children,
  queryClient = queryClientInstance
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <UserProvider user={user} loadUser={loadUser}>
          <ThemeProvider theme={LightTheme}>
            <ModalProvider>{children}</ModalProvider>
          </ThemeProvider>
        </UserProvider>
      </CookiesProvider>
      {/* uncomment/comment to enable/disable react query dev tools */}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}

EntryProviders.propTypes = {
  loadUser: PropTypes.bool,
  user: PropTypes.object,
  children: PropTypes.node.isRequired
}

export default EntryProviders
