export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1))
    let temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }

  return array
}

export const afterDelay = (delay) => {
  return new Promise((resolve) => setTimeout(resolve, delay))
}

//Calls all the functions it's given, useful when you
//need to call more than 1 function in a onClick event
export const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args))

export const camelToSnake = (obj) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`),
      value
    ])
  )
