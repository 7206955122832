import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { css } from '@styled-system/css'

import { axios } from 'api'

import { Box, Collapse } from 'ui/acro'
import CloseIcon from 'ui/acro/blocks/ContentModal/CloseIcon'
import { TwinkleIcon } from 'components_v2/Icons'

const StyledAlertBar = styled(Box)((props) =>
  css({
    fontFamily: 'proxima-nova, Arial, sans-serif',
    color: 'white',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: '1',
    background: props.theme.colors.alert.medium,
    py: ['13px', '13px'],
    px: ['33px', '48px'], // Account for dismiss/close icon,
    '.message': {
      paddingLeft: '12px'
    },
    '.twinkle-icon': {
      flex: '0 0 20px'
    }
  })
)

const DismissButton = styled('a')((props) =>
  css({
    background: props.theme.colors.alert.dark,
    borderRadius: '50%',
    display: 'block',
    padding: '8px',
    height: '30px',
    width: '30px',
    textDecoration: 'none',
    ':before': {
      background: 'none'
    },
    ':after': {
      background: 'none'
    }
  })
)

const ActionLink = styled('a')((props) =>
  css({
    fontWeight: '700',
    textDecoration: 'underline',
    color: 'inherit',
    fontFamily: 'proxima-nova',
    fontSize: ['14px', '16px'],
    letterSpacing: 3,
    ml: ['0px', '46px'],
    textUnderlineOffset: '5px',
    display: ['block', 'inline'],
    '&:hover .ActionText': {
      borderBottom: '1px solid'
    }
  })
)

// This alert bar is used to show site-wide sales and the like.
const AlertBar = () => {
  const [alert, setAlert] = useState({})
  // const [alert, setAlert] = useState({
  //   message: 'Announcing live classes! Schedule and participate in person.',
  //   actionText: 'See Schedule',
  //   actionUrl: 'http://hbo.com',
  //   dismissible: true
  // })
  const [showAlert, setShowAlert] = useState(false)

  const emitAlertEvent = (visible) => {
    if (typeof CustomEvent === 'function') {
      const event = new CustomEvent('alert_bar_status', { detail: visible })

      // Wait for alert bar to transition, then emit event (see StyledHeader.js)
      setTimeout(() => {
        window.dispatchEvent(event)
      }, 350)
    }
  }

  useEffect(() => {
    const getAlert = async () => {
      try {
        const res = await axios.get('/alert_bar/current')

        if (res.data) {
          setAlert(res.data)
          setShowAlert(true)
          emitAlertEvent(true)
        } else {
          emitAlertEvent(false)
        }
      } catch (error) {
        // Fail silently
        // console.log(error)
      }
    }

    getAlert()
  }, [])

  const dismissAlert = async () => {
    try {
      await axios.post('/alert_bar/dismiss', {})
    } catch (error) {
      // Fail silently
      // console.log(error)
    } finally {
      setShowAlert(false)
      emitAlertEvent(false)
    }
  }

  return (
    <Collapse isOpened={showAlert}>
      <StyledAlertBar id={'AlertBar'}>
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <TwinkleIcon color='white' />
          <div>
            <span
              style={{ paddingLeft: '12px' }}
              dangerouslySetInnerHTML={{ __html: alert.message }}
            />
            {alert.actionText && alert.actionUrl && (
              <ActionLink
                href={alert.actionUrl}
                id='alert_bar_action'
                className='unstyled heap-alertbar-actionlink'
              >
                <span className='ActionText'>{alert.actionText}</span>
              </ActionLink>
            )}
          </div>
        </div>
        <div>
          {alert.dismissible && (
            <DismissButton
              className='unstyled heap-alertbar-closebtn'
              onClick={dismissAlert}
              id='alert_bar_close'
            >
              <CloseIcon closeColor='white' />
            </DismissButton>
          )}
        </div>
      </StyledAlertBar>
    </Collapse>
  )
}

export default AlertBar
